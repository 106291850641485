
import { Component, Vue, Prop } from 'vue-property-decorator'
import { MainInfo } from '@/types/common'

@Component({
  name: 'maintenanceConfigDialog'
})
export default class extends Vue {
  @Prop() readonly maintainTypeShow!: boolean

  private loading = false
  private tableData: MainInfo[] = []

  private oldData: { [key: number]: MainInfo } = {}
  private isAddMaintainType: number | null = null

  created () {
    this.getConfigList()
  }

  getConfigList () {
    this.loading = true
    this.$axios.get(this.$apis.record.selectConfigByList).then(res => {
      if (res) {
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isAddMaintainType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.push({
        typeName: '',
        isInput: true
      })
      this.isAddMaintainType = this.tableData.length - 1
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (row: MainInfo, index: number) {
    if (this.isAddMaintainType != null) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData[index] = {
        ...row,
        isInput: true
      }
      this.oldData[index] = row
      this.isAddMaintainType = index
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  onDelete (typeId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.record.deleteDataConfig, { configId: typeId }).then(() => {
          this.$message.success('删除成功')
          this.isAddMaintainType = null
          this.getConfigList()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onSave (row: MainInfo) {
    if (row.typeName) {
      const info = row.configId
        ? {
          configId: row.configId,
          typeName: row.typeName
        }
        : {
          typeName: row.typeName
        }

      const url = row.configId
        ? this.$apis.record.updateConfig
        : this.$apis.record.insertConfig

      this.$axios.post(url, info).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.isAddMaintainType = null
        this.getConfigList()
      })
    } else {
      this.$message({
        message: '请输入养护类型完整数据',
        type: 'warning'
      })
    }
  }

  onCancel (row: MainInfo, index: number) {
    this.isAddMaintainType = null
    if (row.configId) {
      // 是否为编辑
      this.tableData[index].isInput = false
      this.tableData[index].typeName = this.oldData[index].typeName // 取消值不变
    } else {
      this.tableData.splice(index, 1)
    }
  }

  closeDialog () {
    this.$emit('update:maintainTypeShow', false)
  }
}
