
import { Component, Vue, Prop } from 'vue-property-decorator'
import { MainInfo } from '@/types/common'

@Component({
  name: 'seedingTypeConfigDialog'
})
export default class extends Vue {
  @Prop() readonly seedingTypeShow!: boolean

  private loading = false
  private tableData: MainInfo[] = []

  // 保存修改前的数据
  private oldData: MainInfo = { typeName: '' }
  // 是否在操作
  private isOperating = false

  created () {
    this.getConfigList()
  }

  getConfigList () {
    this.loading = true
    this.$axios.get(this.$apis.record.selectSeedlingTypeByList).then(res => {
      if (res) {
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    if (this.isOperating) {
      this.$message.warning('请先保存！')
    } else {
      this.tableData.push({
        typeName: '',
        isInput: true
      })
      this.isOperating = true
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (row: MainInfo, index: number) {
    if (this.isOperating) {
      this.$message.warning('请先保存！')
    } else {
      row.isInput = true
      // 保存旧数据
      this.oldData = row
      this.isOperating = true
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
    }
  }

  onDelete (typeId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.record.deleteSeedlingType, { typeId: typeId }).then(() => {
        this.$message.success('删除成功')
        this.isOperating = false
        this.getConfigList()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onSave (row: MainInfo) {
    // 验证
    if (!row.typeName) {
      this.$message({
        message: '请输入苗木类别完整数据',
        type: 'warning'
      })
      return false
    }
    this.sendRequset(row)
  }

  sendRequset (row: MainInfo) {
    let url = this.$apis.record.insertSeedlingType
    let info: {typeName: string; typeId?: string } = { typeName: row.typeName }
    // 修改
    if (row.typeId) {
      url = this.$apis.record.updateSeedlingType
      info = { ...info, typeId: row.typeId }
    }
    this.$axios.post(url, info).then(() => {
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      this.isOperating = false
      this.getConfigList()
    })
  }

  onCancel (row: MainInfo, index: number) {
    this.isOperating = false
    if (row.typeId) {
      // 是否为编辑
      this.oldData.isInput = false
      row = this.oldData // 取消值不变
    } else {
      this.tableData.splice(index, 1)
    }
  }

  closeDialog () {
    this.$emit('closeDialog')
    this.$emit('update:seedingTypeShow', false)
  }
}
